<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            ยินดีต้อนรับเข้าสู่เว็บไซต์! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- ชื่อบัญชีผู้ใช้ -->
              <b-form-group
                label="ชื่อบัญชีผู้ใช้"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="inputUsername"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                    placeholder="ชื่อบัญชีผู้ใช้"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">รหัสผ่าน</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="inputPassword"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="isWrongLogin"
                    class="text-danger d-block"
                  >
                    ชื่อบัญชีผู้ใช้หรือรหัสผ่านไม่ถูกต้อง
                  </small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="fncSubmit"
              >
                ล็อกอินเข้าสู่ระบบ
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import axios from '@axios'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },

  setup() {
    // ประกาศตัวแปร Textbox
    const inputUsername = ref(null)
    const inputPassword = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const isWrongLogin = ref(false)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่นเมื่อกดปุ่มยืนยัน */
    const fncSubmit = () => {
      const payload = {
        member_no: inputUsername.value,
        password: inputPassword.value,
      }

      axios
        .post('/auth/Login', { requestData: payload })
        .then(response => {
          if (response.data.statusCode === 200) {
            localStorage.setItem('userData', JSON.stringify(response.data.value.member)) // set localstorage ข้อมูลล็อกอิน
            localStorage.setItem('accessToken', response.data.value.token) // set localstorage token
            isWrongLogin.value = false

            router.push({ name: 'home' })
          } else isWrongLogin.value = true

          // if (response.data.responseData.member_no) {
          //   isWrongLogin.value = false
          //   localStorage.setItem('userData', JSON.stringify(response.data.responseData)) // set localstorage ข้อมูลล็อกอิน
          //   localStorage.setItem('setupTime', new Date().getTime()) // set localstorage เวลาล็อกอิน
          //   router.push({ name: 'home' })
          // } else {
          //   isWrongLogin.value = true
          // }
        })
        .catch(error => console.log(error))
    }

    return {
      // ประกาศตัวแปร Textbox
      inputUsername,
      inputPassword,

      // ประกาศตัวแปรสำหรับเก็บค่า
      isWrongLogin,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
